<template>
  <div class="change-user">
    <input
      type="text"
      class="form-control  form-control-sm change-user-input shadow-none"
      placeholder="сменить пользователя"
      v-model="input"
      @input="usersFilter"
    />
    <div v-if="usersFilterVisible" class="change-user-list-wrapper">
      <div class="list-group change-user-list">
        <a
          v-for="user in filterUsers"
          :key="user.value"
          href="#"
          class="list-group-item list-group-item-action change-user-list-item"
          @click="handleClick(user.value)"
          >{{ user.label }}</a
        >
      </div>
    </div>
    <div v-else />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapActions } from "vuex";
export default {
  name: "changeUser",
  data: () => ({
    users: [],
    filterUsers: [],
    input: "",
    usersFilterVisible: false
  }),
  methods: {
    ...mapActions(["CHANGE_USER"]),
    usersFilter() {
      this.filterUsers = this.users.filter(item =>
        item.label.toLowerCase().includes(this.input.toLowerCase())
      );
      if (this.input.length > 0) {
        this.usersFilterVisible = true;
      } else {
        this.usersFilterVisible = false;
      }
    },
    handleClick(id) {
      this.usersFilterVisible = false;
      this.CHANGE_USER(id);
      this.input = "";
    }
  },
  mounted() {
    HTTPGET("home/change_user/get_list_users.php").then(
      response => (this.users = response.data)
    );
  }
};
</script>
<style scoped lang="scss">
.change-user {
  margin-bottom: 10px;
  position: relative;
  &-input {
    height: 2em;
  }
  &-list-wrapper {
    position: absolute;
    z-index: 5;
    width: 100%;
    top: 2em;
    left: 0;
  }
  &-list-item {
    padding: 3px;
    width: 100%;
    font-size: 0.8em;
  }
  &-list {
    max-height: 250px;
    overflow: auto;
  }
}
</style>
