<template>
  <div>
    <div><UserInfo /></div>
    <div v-if="CHANGE_USER_MENU_VISIBLE"><ChangeUser /></div>
    <div v-else />
  </div>
</template>
<script>
import UserInfo from "./UserInfo";
import ChangeUser from "./ChangeUser";
import { mapGetters } from "vuex";
export default {
  name: "HomeContent",
  components: {
    UserInfo,
    ChangeUser
  },
  computed: {
    ...mapGetters(["CHANGE_USER_MENU_VISIBLE"])
  }
};
</script>
