<template>
  <div id="home-wrapper" class="home-wrapper">
    <div class="home-container home-container-left"><HomeContent /></div>
    <div class="home-container home-container-right"><News /></div>
  </div>
</template>

<script>
import News from "@/components/News";
import HomeContent from "@/components/homeContent";
export default {
  name: "Home",
  methods: {
    heigthNavbar() {
      const navbarHeigth = document.getElementById("navbar").clientHeight;
      const documentHeigth = window.innerHeight;
      const getHomeWrapper = document.getElementById("home-wrapper");
      getHomeWrapper.style.height = documentHeigth - navbarHeigth + "px";
    }
  },
  mounted() {
    this.heigthNavbar();
  },
  components: {
    News,
    HomeContent
  }
};
</script>
<style scoped>
.home-wrapper {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}
.home-container-left {
  width: 70%;
}
.home-container-right {
  width: 30%;
  overflow: auto;
}
.home-container {
  padding: 10px;
}
@media (max-width: 600px) {
  .home-wrapper {
    display: block;
  }
  .home-container-right,
  .home-container-left {
    width: auto;
  }
}
</style>
