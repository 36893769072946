<template>
  <div>
    <div v-for="n of news" :key="n.id" class="card">
      <div class="card-body">
        <h5 class="card-title">{{ n.header_news }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ n.date_news }}</h6>
        <p class="card-text">{{ n.text_news }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "News",
  data: () => ({
    news: null
  }),
  mounted() {
    HTTPGET("home/news/get_news.php").then(
      response => (this.news = response.data)
    );
  }
};
</script>
<style scoped>
.card {
  margin-bottom: 5px;
  font-size: 0.8em;
}
</style>
