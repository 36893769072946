<template>
  <table class="table user-info-table">
    <tbody>
      <tr v-for="item of userInfo" :key="item.id">
        <td>{{ item.name }}</td>
        <td v-if="item.data.includes('@')">
          <a :href="`mailto:${item.data}`">{{ item.data }}</a>
        </td>
        <td v-else>{{ item.data }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "UserInfo",
  data: () => ({
    userInfo: null
  }),
  mounted() {
    HTTPGET("user/data_user/data_user.php").then(
      response => (this.userInfo = response.data)
    );
  }
};
</script>
